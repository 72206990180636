<template>
  <div class="main">
       <div class="top">
      <div class="back" @click="onClickLeft('/myCommunity')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
        {{$t('m.wdfx')}}
      </div>
    </div>  
    <div class="card">

  
    <div class="list"> 
      <div class="list-item">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" loosing-text="loading">
          <van-list
            v-model="loading"
            :finished="finished"
             loading-text="loading"
            finished-text=""
            @load="onLoad"            
          >
            <div v-for="(item, i) in list" :key="i"   class="item">
              <div class="items" @click="toshow(i,item.show)">            
            <div class="tops">
                    <div class="time">{{ item.userAddress | yingcang}}</div>
                    <div class="status">{{ item.runInvestment>0?$t('m.yxyh'):$t('m.wxyh') }}</div>
                    </div>
             </div>

             <div class="items border fadeInUp " v-if="item.show">  
                 <div class="line ">            
            <div class="left">{{ $t("m.yhdj") }}  </div>
            <div class="right">V{{ item.userLevel>0?item.userLevel:'0' }}</div>
            </div>
            <div class="line ">            
            <div class="left">{{$t('m.grtr')}} </div>
            <div class="right">{{ item.totalInvestment||0 }}</div>
            </div>
        <div class="line ">            
            <div class="left">{{$t('m.sqyj')}}</div>
            <div class="right">{{ item.communityPerformance||'0' }}</div>
            </div>
           
           <div class="line ">            
            <div class="left">{{$t('m.xqyj')}} </div>
            <div class="right">{{ item.subCommunityPerformance||'0' }}</div>
            </div>
            <div class="line ">            
            <div class="left">{{$t('m.sfjd')}} </div>
            <div class="right">{{ item.isNode?$t('m.shi'):$t('m.fou') }}
            </div>
            </div>
             </div>
            </div>
              <div v-if="list.length==0"  class="noData">
                  <img src="../assets/img/logo.png" alt="" >
                  <div class="ttx">{{$t('m.mysj')}}</div>
                </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
      <!-- <div class="buttoms">Copyright © 2024 ARC. All rights reserved.</div> -->

  </div>
</template>

<script>
import {getReferralsInfo} from "@/api/Public";
export default {
  data() {
    return {
      userWallet: "",
      codeWallet:
        "https://dapp.beastking.org?code=" + this.$store.getters.userWallet,
      list: [],
      total: 0,
      page: 1,
      loading: false,
      finished: false,
      refreshing: false,
      menuShow:false,
      info:'',
      setitem:'',
      setshow:false,
      value:'',

    };
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    }
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  beforeCreate() {
    // var a = setInterval(() => {
    //   if (typeof window.ContractEth !== "undefined") {
    //     this.getlist();
    //     clearInterval(a);
    //   } else {
    //     set();
    //   }
    // }, 1000);
  },
  created() {
    this.userWallet = this.$store.getters.userWallet;
    // this.getInfo()
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.onClickLeft('/')
    },
    deep: true,
    immediate: true,
  },
  methods: {
    toJoin(){
      this.loading=true
        this.$get({
        url: "usm-sub/setSpot",
        data: {invAddress:this.setitem.inviteAddress,
        trxAddress:this.setitem.trxAddress,
        spot:this.value
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
              if(res.data){
                this.setshow=false
                this.onRefresh()
                  this.$notify({
                message: this.$i18n.t("m.szcg"),
                type: "success",
              });
              }else{
                this.$notify(this.$i18n.t("m.szsb"))
              }
          });
        },
      });
    },
    toset(e){
      this.setitem=e
      this.setshow=true
    },
      to(url) {
      location.href = url;
    },
    onClickLeft(val) {
       this.$router.replace({ path:val})
    },
    toshow(e,j){
      this.$set(this.list[e],'show',!j)
    },
    getInfo(){
       this.$get({
        url: "wms-wallet/getWallet",
        data: { trxAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.info=res.data
              }
          });
        },
      });
    },
  menu() {
      this.menuShow = true;
    },
       gbsmenu() {
      this.menuShow = false;
    },
     goto(val) {
      this.$router.push({ path: val });
    },
      changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.page=1
      this.onLoad();
    },

    onLoad() {
      this.finished = true;
      this.getlist()
      return
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      // this.list = [{address:''}];
      this.$get({
        url: "wms-wallet/getInvitList",
        data: {
          pageNum : this.page,
          pageSize :10,
          uid: this.$store.getters.myID
        },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.loading = false;
              res.data.map(el=>{
                el.show=false
                return el
              })
              if (this.page > 1) {
                this.list = [...this.list, ...res.data];
              } else {
                this.list = res.data;
              }
              if (res.data.length >= 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },

    async getlist() {
      this.refreshing = false;
      // this.userWallet="0x64Cf83744682B88C2a9b287a0a7008a9a8dDb5ae"
      getReferralsInfo(this.userWallet).then(res=>{
        if(res){
          this.list=[]
          res.map(el=>{
            let obj=Object.assign({},el)
            obj.totalInvestment=(el.totalInvestment/10**18)
            obj.communityPerformance=(el.communityPerformance/10**18)
            obj.subCommunityPerformance=(el.subCommunityPerformance/10**18)
            obj.show=false
            this.list.push(obj)
          })
        }
      })

      this.finished = true;
      console.log(this.list);
    },
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.main {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  background-size:contain;
  background-position: center bottom;
  // overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
 .top { background: rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
        position: relative;
      .back {
      position: absolute;
    left: 20px;
       width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title{
         background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }

    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
   .mdex{
    font-size: 24px;
    color: #fff;
    background: rgba(0 0 0 / 24%);
    padding: 20px;
    border-top:1px solid  #6d6d6d
  }
  .card {
    // width: 652px;
    // background: linear-gradient(18deg, #ffdf2d, #00e2be);
    // border-radius: 20px;
    // padding: 20px;
    // margin: 20px auto;
      width: 694px;
      height: 100%;
      border-radius: 20px;
      margin: 0 auto;
      padding: 8px 0 20px;
       color:#000;
// border: 2px solid #aee1ae;
.title {
        margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 40px;
        font-family: $t-mf;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;       
        .node {
          font-size: 30px;
          font-weight: 600;
          color: #8B8B94;
          line-height: 32px;
        }
      }
  .lists {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item {
          flex: 1;
          overflow: hidden;
 .num {
        margin-top: 12px;
        font-size: 32px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #b4184c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#888888;
        line-height: 30px;
        margin-top: 12px;
      }
        }
      }
    .tips {
      font-size: 24px;
      font-family: $t-mf;
      font-weight: 400;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      margin: 22px 0 0;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }
    .stitle{
      font-weight: 600;
      font-size: 28px;
      margin: 20px auto;
      text-align: center;
      width: 200px;
      padding: 10px;
      border-radius: 30px 0;
      color:#ffffff;
          border: 2px solid #62e187;
    }
  .list {
    // height: calc(100vh - 100px);
    background: #00000070;
    border-radius: 60px;
    margin: 20px 0px 20px;
    overflow: auto;
    border: 1px solid rgb(53, 53, 53);
    padding: 30px 40px;
    box-sizing: border-box;
     ::v-deep.van-list{
      height: 100vh;
    overflow: auto;
    }
    .title{
      font-weight: 600;
      font-size: 28px;
      margin: 20px auto;
      text-align: center;
      width: 200px;
      padding: 10px;
      border-radius: 30px 0;
      color:#ffffff;
          border: 2px solid #62e187;
    }
    .list-top {
      display: flex;
      align-items: left;
      justify-content: space-between;
      margin: 50px 0 0;
      border-top: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
      padding: 30px 30px 30px;
      div {
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #8b8b93;
        line-height: 34px;
        text-align: left;
        white-space: nowrap;
        margin: 0 5px;
      }
    }
    .list-item {
      width: 100%;
      min-height: 80vh;
      .van-list{
        min-height: 80vh;
      }
      div {
        font-size: 24px;
        font-family: $t-f;
        font-weight: 400;
        color: #666666;
        line-height: 34px;
        text-align: left;
        // margin: 0 5px;
        white-space: nowrap;
        height: 100%;
        .fuzhi {
          background: transparent;
          border: none;
          width: 30px;
        }
        .fz {
          width: 28px;
          height: 26px;
          margin: 0 -5px;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      align-items: center;
      background: linear-gradient(135.46deg,rgba(6,6,6,.5) 24.8%,rgba(32,32,32,.5) 57.07%);
      border: 1px solid #35dcf7;
      border-radius: 40px;
      margin: 18px 0;
      padding: 30px 40px;
      font-weight: 400;
      font-size: 28px;
      font-family: $t-f;

        .items{
         display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      flex-direction: column;
         .tops{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .time{
              font-size: 26px;
              color: #ABABAB;
              max-width: 50%;
              overflow: hidden;
            }
            .status{
              border-radius: 56px;
              color:#ffffff;
              background: #2A2A2A;
              padding:10px 20px;
            }
          }
      .line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px;
        width: 100%;
        flex: 1;
        .left {
        text-align: left;
          font-size: 24px;
          font-family: $t-mf;
          font-weight: 400;
          color: #f8f8f8;
          line-height: 32px;
      }
      .right {
        font-size: 28px;
        font-family: $t-f;
        color: #35dcf7;
        // background: linear-gradient(180deg, #00d5ff, #ffffff);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        .dw{
          font-size: 22px;
          color: #12c2fc;
        }
            
      }
      }}
      .border{
        margin-top: 20px;
        border: 1px solid #353535;
        padding: 16px;
        border-radius: 16px;
      }
      }
    }
    .d1 {
      width: 50%;
    }
    .d2 {
      width: 50%;
      text-align: right !important;
    }
  }
  .popup {
    width: 672px;
    background: #ffffff;
    border-radius: 30px;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    .title {
      font-size: 48px;
      font-family: $t-mf;
      font-weight: 600;
      color: #222222;
      line-height: 66px;
      margin: 44px auto;
    }
    .xztype{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
       ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .item{
        width: calc(100% - 200px);
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px 0;
      justify-content: center;
            
        }
        .act{
               background:linear-gradient(315deg, #060b0c 0%, #0e4942 74%, #121212 100%);
        // border-radius: 10px;
        color: #ffffff;
        }
    }
      .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: #b4184c;
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
// border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    }
  .mentop {
    display: flex;
    justify-content: space-between;
    margin: 44px 30px;
    .logo {
      // height: 82px;
      width: 232px;
    }
    .gb {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }
  }
  .menulist {
    margin-top: 100px;
    .menuitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 44px;
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 30px;
      .left {
        flex: 1;
        text-align: left;
        display: flex;
        img {
          height: 48px;
          width: 48px;
        }
        .txt {
          margin-left: 40px;
          font-size: 36px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 50px;
        }
      }
      .right {
        img {
          height: 38px;
          width: 26px;
        }
      }
    }
      .act{
       .txt {
         color: #2f73fd !important;
       }
    }
  }
  .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 0;
      padding-bottom: 20px;
      width: 100%;
      position: relative;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
       .noData{
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
    min-height: 80vh;
		img{
			width: 242px;
			margin: 150px auto 20px;
		}
     .ttx{
      font-size:32px;
      color: #ffffff !important;
  font-family: $t-f;
   font-weight: bold;
    }
	}
}
</style>